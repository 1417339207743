// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --primary: #104f3a;
  --text-primary: #fff;
  --accent: #5d942c;
  --text-accent: #fff;
  --text-header-bar: 'transparent';
  --text-primary-accent: rgba(57, 140, 102, 1);

  --status-green: rgba(57, 140, 102, 1);
  --status-green-light: rgba(57, 140, 102, 0.2);
  --status-orange: rgba(193, 120, 35, 1);
  --status-orange-light: rgba(193, 120, 35, 0.2);
  --status-red: rgba(193, 50, 35, 1);
  --status-red-light: rgba(193, 50, 35, 0.2);
  --status-not-selected: #808080;
}

@use './assets/styles/ghost_screen';
@use './assets/styles/ghost_cards_min';
@use './assets/styles/ghost_code_injections';
@use './variables';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: var(--primary);
}

.safe-padding-up {
  padding-right: env(safe-area-inset-right, 2rem);
  padding-top: env(safe-area-inset-top, 2rem);
  padding-left: env(safe-area-inset-left, 2rem);
}

.safe-padding-down {
  padding-right: env(safe-area-inset-right, 2rem);
  padding-bottom: env(safe-area-inset-bottom, 2rem);
  padding-left: env(safe-area-inset-left, 2rem);
}

.ghost-content {
  font-size: 62.5%;
  --ghost-accent-color: #0b3a2b;
  @include ghost_screen.ghost-screen;
  @include ghost_cards_min.ghost-card;
  @include ghost_code_injections.code-injections;
}

mention {
  @apply bg-gray-700 text-gray-100 rounded;
  padding: 2px 6px;
}
